import CookieBox from 'cookie-consent-box';

new CookieBox({
  backgroundColor: '#164a57',
  url: '/datenschutz',
  content: {
    title: "Diese Webseite verwendet Cookies",
    content: "Cookies helfen uns bei der Bereitstellung unserer Dienste. Durch die Nutzung unserer Dienste erklären Sie sich damit einverstanden, dass wir Cookies setzen.",
    accept: "Zustimmen",
    learnMore: "Mehr"
  }
}).init();


